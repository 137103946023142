import { useEffect, useMemo, useState, useCallback } from 'react';
import * as anchor from '@project-serum/anchor';

import styled from 'styled-components';
import {Container, Snackbar} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from './candy-machine';
import { AlertState } from './utils';
import { Header } from './Header';
import { MintButton } from './MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';
import logo from './images/logo.png'

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: #013a70;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0;
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    if (props.candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
            anchorWallet,
            props.candyMachineId,
            props.connection,
        );
        setCandyMachine(cndy);
      } catch (e) {
        console.log('There was a problem fetching Candy Machine state');
        console.log(e);
      }
    }
  }, [anchorWallet, props.candyMachineId, props.connection]);

  const onMint = async () => {
    try {
      setIsUserMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = (
            await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
              mintTxId,
              props.txTimeout,
              props.connection,
              true,
          );
        }

        if (status && !status.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
    } finally {
      setIsUserMinting(false);
    }
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  return (
      <Container style={{ marginTop: 24, marginBottom: 24 }}>
        <Container maxWidth="xs" style={{ position: 'relative' }}>
          <div style={{ display: 'grid', justifyItems: 'center'}}>
            <div style={{fontWeight: 'normal', fontSize: '4rem', textAlign: 'center', marginBottom: 12}}>
              BityHelmets
            </div>
            <div style={{maxWidth: '100%'}}>
              <img style={{maxWidth: '100%'}} src={logo} alt="BityHelmet Mint"/>
            </div>
          </div>
          <Paper
              style={{ padding: 24, backgroundColor: 'rgba(228,240,255,0.6)', borderRadius: 0 }}
          >
            {!wallet.connected ? (
                <ConnectButton>Connect Wallet</ConnectButton>
            ) : (
                <>
                  <Header candyMachine={candyMachine} />
                  <MintContainer>
                    {candyMachine?.state.isActive &&
                    candyMachine?.state.gatekeeper &&
                    wallet.publicKey &&
                    wallet.signTransaction ? (
                        <GatewayProvider
                            wallet={{
                              publicKey:
                                  wallet.publicKey ||
                                  new PublicKey(CANDY_MACHINE_PROGRAM),
                              //@ts-ignore
                              signTransaction: wallet.signTransaction,
                            }}
                            gatekeeperNetwork={
                              candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                            }
                            clusterUrl={rpcUrl}
                            options={{ autoShowModal: false }}
                        >
                          <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              onMint={onMint}
                          />
                        </GatewayProvider>
                    ) : (
                        <MintButton
                            candyMachine={candyMachine}
                            isMinting={isUserMinting}
                            onMint={onMint}
                        />
                    )}
                  </MintContainer>
                </>
            )}
          </Paper>
          <small style={{ display: 'block', textAlign: 'center', marginTop: '0.25em', fontSize: '0.75em' }}>
            Connect with your Solana wallet on {process.env.REACT_APP_SOLANA_NETWORK}
          </small>
        </Container>
        <Snackbar
            open={alertState.open}
            autoHideDuration={6000}
            onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
              onClose={() => setAlertState({ ...alertState, open: false })}
              severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>

        {/* NFT COLLECTION INFORMATION */}
        <Container maxWidth='md' style={{ marginTop: 100, marginBottom: 100, backgroundColor: 'rgba(228,240,255,0.6)' }}>
          <div style={{fontSize: '1.75rem', display: 'grid', gridAutoFlow: 'row', rowGap: 36, paddingTop: 36, paddingBottom: 36}}>
            <div>
              <span style={{display: 'inline-block', fontSize: '2.5rem', marginBottom: '1rem'}}>
                ABOUT
              </span>
              <div>
                BityHelmets is a set of 32 digital helmet drawings created by a single artist.
                Each helmet design is uniquely inspired by American pro football.
                {/*timeless collectibles*/}
                <br/>
                <br/>
                BityHelmets are sold as non-fungible tokens (NFTs) on the Solana blockchain,
                providing the single source-of-truth for ownership of each digital asset.
                <br/>
                <br/>
                Mint 1 of 32 unique BityHelmets by connecting your Solana wallet above.
                {/*BityHelmets can be minted ONLY here at bitysports.com.*/}
              </div>
            </div>
            <div>
              <div style={{ width: '100%', height: 18, backgroundColor: '#013a70' }}/>
              <div style={{ width: '100%', height: 18, backgroundColor: '#d70303' }}/>
            </div>
            <div>
              <span style={{display: 'inline-block', fontSize: '2.5rem', marginBottom: '1rem'}}>
                ROADMAP
              </span>
              <div>
                PHASE 1: Mint the BityHelmets NFT collection here, at bitysports.com.
                <br/>
                <br/>
                PHASE 2: List the BityHelmets NFT collection on Magic Eden marketplace.
                <br/>
                <br/>
                PHASE 3: Giveaway BityHelmets themed merchandise to randomly drawn owners.
                <br/>
                <br/>
                ... with more bits to come.
              </div>
            </div>
            <div>
              <div style={{ width: '100%', height: 18, backgroundColor: '#013a70' }}/>
              <div style={{ width: '100%', height: 18, backgroundColor: '#d70303' }}/>
            </div>
            <div>
              <span style={{display: 'inline-block', fontSize: '2.5rem', marginBottom: '1rem'}}>
                LICENSE
              </span>
              <div>
                BityHelmet NFT ownership is recorded on the Solana blockchain and mediated by the "Program" or "Smart Contract" of the NFT.
                No external parties, including ourselves, are capable of modifying the ownership of any BityHelmet NFT.
                <br/>
                <br/>
                Each BityHelmet NFT owner is granted a license for Personal Use and Commercial Use of their NFT artwork.
                No warranties or guarantees are provided.
              </div>
            </div>
            <div>
              <div style={{ width: '100%', height: 18, backgroundColor: '#013a70' }}/>
              <div style={{ width: '100%', height: 18, backgroundColor: '#d70303' }}/>
            </div>
            <div>
              <span style={{display: 'inline-block', fontSize: '2.5rem', marginBottom: '1rem'}}>
                DISCLAIMER
              </span>
              <div>
                BityHelmets has no affiliation with any sports team or sports organization.
                BityHelmets artworks were authentically and uniquely produced by an artist.
                BityHelmets non-fungible tokens are sold as-is, without warranty.
                {/*BityHelmets artworks were created with the aid of computer software tools.*/}
                {/*BityHelmets digital art is subject to copyright by the artist.*/}
                {/*Non-fungible tokens are not guaranteed to have market value.*/}
                {/*None of the statements made here are endorsed by anyone.*/}
              </div>
            </div>
            <div>
              <div style={{ width: '100%', height: 18, backgroundColor: '#013a70' }}/>
              <div style={{ width: '100%', height: 18, backgroundColor: '#d70303' }}/>
            </div>
            <div>
              <span style={{display: 'inline-block', fontSize: '2.5rem', marginBottom: '1rem'}}>
                SOCIAL
              </span>
              <div>
                <a href="https://www.instagram.com/bitysports/"
                   target={'_blank'} rel="noreferrer"
                   style={{textDecoration: 'none', color: '#013a70'}}
                >
                  Instagram
                </a>
                <br/>
                <a href="https://twitter.com/BitySports"
                   target={'_blank'} rel="noreferrer"
                   style={{textDecoration: 'none', color: '#013a70'}}
                >
                  Twitter
                </a>
                <br/>
                <a href="https://www.reddit.com/user/BitySports"
                   target={'_blank'} rel="noreferrer"
                   style={{textDecoration: 'none', color: '#013a70'}}
                >
                  Reddit
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Container>
  );
};

export default Home;
